import React from "react";
import { useTranslation } from "react-i18next";
import Heading from "../Heading"
import './game.scss'

export default ({ data, content }) => {

    return(
        <div id="game" class="blog-area blog-no-sidebar pt-100 pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 blog-post-item ml-auto mr-auto">
                        <div class="blog-wrapper blog-details">
                            <div class="blog-content">
                                <Heading title={data.title} subtitle={data.subtitle} clasName="mt-50" />
                                <div dangerouslySetInnerHTML={{__html: content}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}